/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListArgs } from '../models/ListArgs';
import type { Order } from '../models/Order';
import type { OrderIn } from '../models/OrderIn';
import type { OrderList } from '../models/OrderList';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrderService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Order Objects List
     * @param requestBody
     * @returns OrderList Successful Response
     * @throws ApiError
     */
    public getOrderList(
        requestBody: ListArgs,
    ): CancelablePromise<OrderList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Order Object Create
     * @param requestBody
     * @param itemId
     * @returns Order Successful Response
     * @throws ApiError
     */
    public newOrder(
        requestBody: OrderIn,
        itemId?: (number | null),
    ): CancelablePromise<Order> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Order',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Order Object Get
     * @param itemId
     * @returns Order Successful Response
     * @throws ApiError
     */
    public getOrder(
        itemId: number,
    ): CancelablePromise<Order> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Order/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Order Object Update
     * @param itemId
     * @param requestBody
     * @returns Order Successful Response
     * @throws ApiError
     */
    public updOrder(
        itemId: (number | null),
        requestBody: OrderIn,
    ): CancelablePromise<Order> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Order/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Order Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delOrder(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Order/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
