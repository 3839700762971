import {Accordion, Avatar, Badge, Cell, Info, List} from '@telegram-apps/telegram-ui';
import {client} from "../helpers/client";
import {CancelablePromise, ExAction, ExType, Tests} from "../api";
// @ts-ignore
import {Suspense, use, useState} from "react";
import {AccordionSummary} from "@telegram-apps/telegram-ui/dist/components/Blocks/Accordion/components/AccordionSummary/AccordionSummary";
import {AccordionContent} from "@telegram-apps/telegram-ui/dist/components/Blocks/Accordion/components/AccordionContent/AccordionContent";

const testsPromise: CancelablePromise<Array<Tests>> = client.testEx.getTests()

const TestList = () => {
    const tests = use(testsPromise)
    const [expState, setExpState] = useState({});
    const now = Math.round(Date.now()/1000)
    return tests.map((ex: Tests, k: number) => {
        const oks = ex.tests.reduce((sum, cur) => {return sum + Number(cur.ok)}, 0)
        // @ts-ignore
        return <Accordion key={'k'+k} onChange={() => setExpState({...expState, [k]: !expState[k]})} expanded={expState[k]}>
                <AccordionSummary>
                    <Cell
                        key={'ex'+ex.id}
                        before={<Avatar size={40} src={ex.logo}/>}
                        after={<Info subtitle={oks ? oks < ex.tests.length ? "Partial" : "Full": "None"} type="text"><Badge type="number" mode={oks ? oks < ex.tests.length ? "white" : "primary": "critical"}>{oks}</Badge> / {ex.tests.length}</Info>}
                        subtitle={ExType[ex.type]}
                    >
                        {ex.name}
                    </Cell>
                </AccordionSummary>
                <AccordionContent>
                    <List style={{padding: 15}}>
                        {ex.tests.map(test => {
                            const diff_minutes = (now - Date.parse(test.updated_at || '')/1000) / 3600
                            return <Badge key={test.id} type="number" mode={test.ok !== null? test.ok ? diff_minutes < 36 ? "primary" : "critical" : "gray": "secondary"}>{ExAction[test.action]}</Badge>
                        })}
                    </List>
                </AccordionContent>
            </Accordion>
    })
}

export const TestsSection = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <TestList></TestList>
        </Suspense>
    )
}