/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ex } from '../models/Ex';
import type { ExIn } from '../models/ExIn';
import type { ExList } from '../models/ExList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ExService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Ex Objects List
     * @param requestBody
     * @returns ExList Successful Response
     * @throws ApiError
     */
    public getExList(
        requestBody: ListArgs,
    ): CancelablePromise<ExList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Ex',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ex Object Create
     * @param requestBody
     * @param itemId
     * @returns Ex Successful Response
     * @throws ApiError
     */
    public newEx(
        requestBody: ExIn,
        itemId?: (number | null),
    ): CancelablePromise<Ex> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Ex',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ex Object Get
     * @param itemId
     * @returns Ex Successful Response
     * @throws ApiError
     */
    public getEx(
        itemId: number,
    ): CancelablePromise<Ex> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Ex/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ex Object Update
     * @param itemId
     * @param requestBody
     * @returns Ex Successful Response
     * @throws ApiError
     */
    public updEx(
        itemId: (number | null),
        requestBody: ExIn,
    ): CancelablePromise<Ex> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Ex/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ex Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delEx(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Ex/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
