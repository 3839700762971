/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListArgs } from '../models/ListArgs';
import type { User } from '../models/User';
import type { UserIn } from '../models/UserIn';
import type { UserList } from '../models/UserList';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * User Objects List
     * @param requestBody
     * @returns UserList Successful Response
     * @throws ApiError
     */
    public getUserList(
        requestBody: ListArgs,
    ): CancelablePromise<UserList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/User',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * User Object Create
     * @param requestBody
     * @param itemId
     * @returns User Successful Response
     * @throws ApiError
     */
    public newUser(
        requestBody: UserIn,
        itemId?: (number | null),
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/User',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * User Object Get
     * @param itemId
     * @returns User Successful Response
     * @throws ApiError
     */
    public getUser(
        itemId: number,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/User/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * User Object Update
     * @param itemId
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    public updUser(
        itemId: (number | null),
        requestBody: UserIn,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/User/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * User Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delUser(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/User/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
