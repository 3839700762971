/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Investment } from '../models/Investment';
import type { InvestmentIn } from '../models/InvestmentIn';
import type { InvestmentList } from '../models/InvestmentList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InvestmentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Investment Objects List
     * @param requestBody
     * @returns InvestmentList Successful Response
     * @throws ApiError
     */
    public getInvestmentList(
        requestBody: ListArgs,
    ): CancelablePromise<InvestmentList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Investment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Investment Object Create
     * @param requestBody
     * @param itemId
     * @returns Investment Successful Response
     * @throws ApiError
     */
    public newInvestment(
        requestBody: InvestmentIn,
        itemId?: (number | null),
    ): CancelablePromise<Investment> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Investment',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Investment Object Get
     * @param itemId
     * @returns Investment Successful Response
     * @throws ApiError
     */
    public getInvestment(
        itemId: number,
    ): CancelablePromise<Investment> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Investment/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Investment Object Update
     * @param itemId
     * @param requestBody
     * @returns Investment Successful Response
     * @throws ApiError
     */
    public updInvestment(
        itemId: (number | null),
        requestBody: InvestmentIn,
    ): CancelablePromise<Investment> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Investment/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Investment Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delInvestment(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Investment/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
