/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListArgs } from '../models/ListArgs';
import type { Vpn } from '../models/Vpn';
import type { VpnIn } from '../models/VpnIn';
import type { VpnList } from '../models/VpnList';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class VpnService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Vpn Objects List
     * @param requestBody
     * @returns VpnList Successful Response
     * @throws ApiError
     */
    public getVpnList(
        requestBody: ListArgs,
    ): CancelablePromise<VpnList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Vpn',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Vpn Object Create
     * @param requestBody
     * @param itemId
     * @returns Vpn Successful Response
     * @throws ApiError
     */
    public newVpn(
        requestBody: VpnIn,
        itemId?: (number | null),
    ): CancelablePromise<Vpn> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Vpn',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Vpn Object Get
     * @param itemId
     * @returns Vpn Successful Response
     * @throws ApiError
     */
    public getVpn(
        itemId: number,
    ): CancelablePromise<Vpn> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Vpn/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Vpn Object Update
     * @param itemId
     * @param requestBody
     * @returns Vpn Successful Response
     * @throws ApiError
     */
    public updVpn(
        itemId: (number | null),
        requestBody: VpnIn,
    ): CancelablePromise<Vpn> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Vpn/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Vpn Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delVpn(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Vpn/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
