/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListArgs } from '../models/ListArgs';
import type { Pmcur } from '../models/Pmcur';
import type { PmcurIn } from '../models/PmcurIn';
import type { PmcurList } from '../models/PmcurList';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PmcurService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Pmcur Objects List
     * @param requestBody
     * @returns PmcurList Successful Response
     * @throws ApiError
     */
    public getPmcurList(
        requestBody: ListArgs,
    ): CancelablePromise<PmcurList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Pmcur',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pmcur Object Create
     * @param requestBody
     * @param itemId
     * @returns Pmcur Successful Response
     * @throws ApiError
     */
    public newPmcur(
        requestBody: PmcurIn,
        itemId?: (number | null),
    ): CancelablePromise<Pmcur> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Pmcur',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pmcur Object Get
     * @param itemId
     * @returns Pmcur Successful Response
     * @throws ApiError
     */
    public getPmcur(
        itemId: number,
    ): CancelablePromise<Pmcur> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Pmcur/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pmcur Object Update
     * @param itemId
     * @param requestBody
     * @returns Pmcur Successful Response
     * @throws ApiError
     */
    public updPmcur(
        itemId: (number | null),
        requestBody: PmcurIn,
    ): CancelablePromise<Pmcur> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Pmcur/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pmcur Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delPmcur(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Pmcur/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
