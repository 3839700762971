/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ad } from '../models/Ad';
import type { AdIn } from '../models/AdIn';
import type { AdList } from '../models/AdList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AdService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Ad Objects List
     * @param requestBody
     * @returns AdList Successful Response
     * @throws ApiError
     */
    public getAdList(
        requestBody: ListArgs,
    ): CancelablePromise<AdList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Ad',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ad Object Create
     * @param requestBody
     * @param itemId
     * @returns Ad Successful Response
     * @throws ApiError
     */
    public newAd(
        requestBody: AdIn,
        itemId?: (number | null),
    ): CancelablePromise<Ad> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Ad',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ad Object Get
     * @param itemId
     * @returns Ad Successful Response
     * @throws ApiError
     */
    public getAd(
        itemId: number,
    ): CancelablePromise<Ad> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Ad/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ad Object Update
     * @param itemId
     * @param requestBody
     * @returns Ad Successful Response
     * @throws ApiError
     */
    public updAd(
        itemId: (number | null),
        requestBody: AdIn,
    ): CancelablePromise<Ad> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Ad/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ad Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delAd(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Ad/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
