/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import type { CountryIn } from '../models/CountryIn';
import type { CountryList } from '../models/CountryList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CountryService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Country Objects List
     * @param requestBody
     * @returns CountryList Successful Response
     * @throws ApiError
     */
    public getCountryList(
        requestBody: ListArgs,
    ): CancelablePromise<CountryList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Country',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Country Object Create
     * @param requestBody
     * @param itemId
     * @returns Country Successful Response
     * @throws ApiError
     */
    public newCountry(
        requestBody: CountryIn,
        itemId?: (number | null),
    ): CancelablePromise<Country> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Country',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Country Object Get
     * @param itemId
     * @returns Country Successful Response
     * @throws ApiError
     */
    public getCountry(
        itemId: number,
    ): CancelablePromise<Country> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Country/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Country Object Update
     * @param itemId
     * @param requestBody
     * @returns Country Successful Response
     * @throws ApiError
     */
    public updCountry(
        itemId: (number | null),
        requestBody: CountryIn,
    ): CancelablePromise<Country> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Country/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Country Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delCountry(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Country/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
