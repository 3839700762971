import {Client} from "../api";

const base: string = 'https://api.xync.net'+(process.env.DEV??':88')

const get_tok = async () => {
    const cookies = document.cookie.split('; ');
    let tok: string | undefined = cookies.find(c => c.startsWith('access_token='));
    if (tok && tok!=='undefined') { return tok }
    // @ts-ignore
    tok = (await fetch(base + '/auth/twa-token?' + window?.Telegram?.WebApp.initData))['access_token']
    if (tok && tok!=='undefined') { document.cookie = 'access_token='+tok }
    return tok
}

export const client = new Client({
    BASE: base,
    // @ts-ignore
    TOKEN: get_tok(),
})