/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Invite } from '../models/Invite';
import type { InviteIn } from '../models/InviteIn';
import type { InviteList } from '../models/InviteList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InviteService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Invite Objects List
     * @param requestBody
     * @returns InviteList Successful Response
     * @throws ApiError
     */
    public getInviteList(
        requestBody: ListArgs,
    ): CancelablePromise<InviteList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Invite',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Invite Object Create
     * @param requestBody
     * @param itemId
     * @returns Invite Successful Response
     * @throws ApiError
     */
    public newInvite(
        requestBody: InviteIn,
        itemId?: (number | null),
    ): CancelablePromise<Invite> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Invite',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Invite Object Get
     * @param itemId
     * @returns Invite Successful Response
     * @throws ApiError
     */
    public getInvite(
        itemId: number,
    ): CancelablePromise<Invite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Invite/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Invite Object Update
     * @param itemId
     * @param requestBody
     * @returns Invite Successful Response
     * @throws ApiError
     */
    public updInvite(
        itemId: (number | null),
        requestBody: InviteIn,
    ): CancelablePromise<Invite> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Invite/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Invite Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delInvite(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Invite/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
