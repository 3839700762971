/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListArgs } from '../models/ListArgs';
import type { Pair } from '../models/Pair';
import type { PairIn } from '../models/PairIn';
import type { PairList } from '../models/PairList';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PairService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Pair Objects List
     * @param requestBody
     * @returns PairList Successful Response
     * @throws ApiError
     */
    public getPairList(
        requestBody: ListArgs,
    ): CancelablePromise<PairList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Pair',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pair Object Create
     * @param requestBody
     * @param itemId
     * @returns Pair Successful Response
     * @throws ApiError
     */
    public newPair(
        requestBody: PairIn,
        itemId?: (number | null),
    ): CancelablePromise<Pair> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Pair',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pair Object Get
     * @param itemId
     * @returns Pair Successful Response
     * @throws ApiError
     */
    public getPair(
        itemId: number,
    ): CancelablePromise<Pair> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Pair/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pair Object Update
     * @param itemId
     * @param requestBody
     * @returns Pair Successful Response
     * @throws ApiError
     */
    public updPair(
        itemId: (number | null),
        requestBody: PairIn,
    ): CancelablePromise<Pair> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Pair/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pair Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delPair(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Pair/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
