/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Fiat } from '../models/Fiat';
import type { FiatIn } from '../models/FiatIn';
import type { FiatList } from '../models/FiatList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FiatService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Fiat Objects List
     * @param requestBody
     * @returns FiatList Successful Response
     * @throws ApiError
     */
    public getFiatList(
        requestBody: ListArgs,
    ): CancelablePromise<FiatList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Fiat',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Fiat Object Create
     * @param requestBody
     * @param itemId
     * @returns Fiat Successful Response
     * @throws ApiError
     */
    public newFiat(
        requestBody: FiatIn,
        itemId?: (number | null),
    ): CancelablePromise<Fiat> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Fiat',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Fiat Object Get
     * @param itemId
     * @returns Fiat Successful Response
     * @throws ApiError
     */
    public getFiat(
        itemId: number,
    ): CancelablePromise<Fiat> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Fiat/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Fiat Object Update
     * @param itemId
     * @param requestBody
     * @returns Fiat Successful Response
     * @throws ApiError
     */
    public updFiat(
        itemId: (number | null),
        requestBody: FiatIn,
    ): CancelablePromise<Fiat> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Fiat/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Fiat Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delFiat(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Fiat/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
