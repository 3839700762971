/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Direction } from '../models/Direction';
import type { DirectionIn } from '../models/DirectionIn';
import type { DirectionList } from '../models/DirectionList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DirectionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Direction Objects List
     * @param requestBody
     * @returns DirectionList Successful Response
     * @throws ApiError
     */
    public getDirectionList(
        requestBody: ListArgs,
    ): CancelablePromise<DirectionList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Direction',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Direction Object Create
     * @param requestBody
     * @param itemId
     * @returns Direction Successful Response
     * @throws ApiError
     */
    public newDirection(
        requestBody: DirectionIn,
        itemId?: (number | null),
    ): CancelablePromise<Direction> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Direction',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Direction Object Get
     * @param itemId
     * @returns Direction Successful Response
     * @throws ApiError
     */
    public getDirection(
        itemId: number,
    ): CancelablePromise<Direction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Direction/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Direction Object Update
     * @param itemId
     * @param requestBody
     * @returns Direction Successful Response
     * @throws ApiError
     */
    public updDirection(
        itemId: (number | null),
        requestBody: DirectionIn,
    ): CancelablePromise<Direction> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Direction/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Direction Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delDirection(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Direction/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
