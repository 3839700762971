/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Agent } from '../models/Agent';
import type { AgentIn } from '../models/AgentIn';
import type { AgentList } from '../models/AgentList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AgentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Agent Objects List
     * @param requestBody
     * @returns AgentList Successful Response
     * @throws ApiError
     */
    public getAgentList(
        requestBody: ListArgs,
    ): CancelablePromise<AgentList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Agent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Agent Object Create
     * @param requestBody
     * @param itemId
     * @returns Agent Successful Response
     * @throws ApiError
     */
    public newAgent(
        requestBody: AgentIn,
        itemId?: (number | null),
    ): CancelablePromise<Agent> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Agent',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Agent Object Get
     * @param itemId
     * @returns Agent Successful Response
     * @throws ApiError
     */
    public getAgent(
        itemId: number,
    ): CancelablePromise<Agent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Agent/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Agent Object Update
     * @param itemId
     * @param requestBody
     * @returns Agent Successful Response
     * @throws ApiError
     */
    public updAgent(
        itemId: (number | null),
        requestBody: AgentIn,
    ): CancelablePromise<Agent> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Agent/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Agent Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delAgent(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Agent/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
