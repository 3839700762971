/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ExType {
    p2p = 1,
    cex = 2,
    main = 3,
    dex = 4,
    futures = 8,
}
