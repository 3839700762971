/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Credit } from '../models/Credit';
import type { CreditIn } from '../models/CreditIn';
import type { CreditList } from '../models/CreditList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CreditService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Credit Objects List
     * @param requestBody
     * @returns CreditList Successful Response
     * @throws ApiError
     */
    public getCreditList(
        requestBody: ListArgs,
    ): CancelablePromise<CreditList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Credit',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Credit Object Create
     * @param requestBody
     * @param itemId
     * @returns Credit Successful Response
     * @throws ApiError
     */
    public newCredit(
        requestBody: CreditIn,
        itemId?: (number | null),
    ): CancelablePromise<Credit> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Credit',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Credit Object Get
     * @param itemId
     * @returns Credit Successful Response
     * @throws ApiError
     */
    public getCredit(
        itemId: number,
    ): CancelablePromise<Credit> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Credit/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Credit Object Update
     * @param itemId
     * @param requestBody
     * @returns Credit Successful Response
     * @throws ApiError
     */
    public updCredit(
        itemId: (number | null),
        requestBody: CreditIn,
    ): CancelablePromise<Credit> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Credit/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Credit Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delCredit(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Credit/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
