/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coin } from '../models/Coin';
import type { CoinIn } from '../models/CoinIn';
import type { CoinList } from '../models/CoinList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CoinService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Coin Objects List
     * @param requestBody
     * @returns CoinList Successful Response
     * @throws ApiError
     */
    public getCoinList(
        requestBody: ListArgs,
    ): CancelablePromise<CoinList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Coin',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Coin Object Create
     * @param requestBody
     * @param itemId
     * @returns Coin Successful Response
     * @throws ApiError
     */
    public newCoin(
        requestBody: CoinIn,
        itemId?: (number | null),
    ): CancelablePromise<Coin> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Coin',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Coin Object Get
     * @param itemId
     * @returns Coin Successful Response
     * @throws ApiError
     */
    public getCoin(
        itemId: number,
    ): CancelablePromise<Coin> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Coin/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Coin Object Update
     * @param itemId
     * @param requestBody
     * @returns Coin Successful Response
     * @throws ApiError
     */
    public updCoin(
        itemId: (number | null),
        requestBody: CoinIn,
    ): CancelablePromise<Coin> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Coin/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Coin Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delCoin(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Coin/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
