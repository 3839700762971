/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Asset } from '../models/Asset';
import type { AssetIn } from '../models/AssetIn';
import type { AssetList } from '../models/AssetList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AssetService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Asset Objects List
     * @param requestBody
     * @returns AssetList Successful Response
     * @throws ApiError
     */
    public getAssetList(
        requestBody: ListArgs,
    ): CancelablePromise<AssetList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Asset',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Asset Object Create
     * @param requestBody
     * @param itemId
     * @returns Asset Successful Response
     * @throws ApiError
     */
    public newAsset(
        requestBody: AssetIn,
        itemId?: (number | null),
    ): CancelablePromise<Asset> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Asset',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Asset Object Get
     * @param itemId
     * @returns Asset Successful Response
     * @throws ApiError
     */
    public getAsset(
        itemId: number,
    ): CancelablePromise<Asset> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Asset/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Asset Object Update
     * @param itemId
     * @param requestBody
     * @returns Asset Successful Response
     * @throws ApiError
     */
    public updAsset(
        itemId: (number | null),
        requestBody: AssetIn,
    ): CancelablePromise<Asset> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Asset/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Asset Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delAsset(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Asset/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
