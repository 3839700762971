/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Public
 */
export enum ExAction {
    all_curs_taker = 1,
    all_coins = 2,
    all_pms = 3,
    all_ads = 4,
    fiats = 5,
    fiat_new = 6,
    fiat_upd = 7,
    fiat_del = 8,
    my_ads = 9,
    ad_new = 10,
    ad_upd = 11,
    ad_del = 12,
    ad_on = 13,
    ad_off = 14,
    order_approve = 15,
    order_reject = 16,
    take_ad = 17,
    order_cancel = 18,
    order_paid = 19,
    order_payment_confirm = 20,
    get_chat = 21,
    send_msg = 22,
    appeal_start = 23,
    appeal_accept = 24,
    appeal_cancel = 25,
    send_appeal_msg = 26,
    user_block = 27,
    user_review_post = 28,
    user_rating = 29,
    ws_chat = 30,
    ws_status = 31,
    get_orders = 32,
    get_order = 33,
}
