/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dep } from '../models/Dep';
import type { DepIn } from '../models/DepIn';
import type { DepList } from '../models/DepList';
import type { ListArgs } from '../models/ListArgs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DepService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Dep Objects List
     * @param requestBody
     * @returns DepList Successful Response
     * @throws ApiError
     */
    public getDepList(
        requestBody: ListArgs,
    ): CancelablePromise<DepList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Dep',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Dep Object Create
     * @param requestBody
     * @param itemId
     * @returns Dep Successful Response
     * @throws ApiError
     */
    public newDep(
        requestBody: DepIn,
        itemId?: (number | null),
    ): CancelablePromise<Dep> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Dep',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Dep Object Get
     * @param itemId
     * @returns Dep Successful Response
     * @throws ApiError
     */
    public getDep(
        itemId: number,
    ): CancelablePromise<Dep> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Dep/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Dep Object Update
     * @param itemId
     * @param requestBody
     * @returns Dep Successful Response
     * @throws ApiError
     */
    public updDep(
        itemId: (number | null),
        requestBody: DepIn,
    ): CancelablePromise<Dep> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Dep/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Dep Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delDep(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Dep/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
