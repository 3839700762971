import {Headline, List, Section, Subheadline, Tabbar} from '@telegram-apps/telegram-ui';
import {TabbarItem} from "@telegram-apps/telegram-ui/dist/components/Layout/Tabbar/components/TabbarItem/TabbarItem";
import {Icon28Devices} from "@telegram-apps/telegram-ui/dist/icons/28/devices";
import {Icon28Stats} from "@telegram-apps/telegram-ui/dist/icons/28/stats";
import {Icon28Heart} from "@telegram-apps/telegram-ui/dist/icons/28/heart";
import {Icon28AddCircle} from "@telegram-apps/telegram-ui/dist/icons/28/add_circle";
import {TestsSection} from "./TestsSection";

export const CellSection = () => {
    return (
        <List style={{background: 'var(--tgui--secondary_bg_color)'}}>
            <Section
                header={<Headline weight="3" style={{padding: 15}}>Exchanges Tests</Headline>}
                footer={<Subheadline style={{padding: 15}}>Footer</Subheadline>}
                // style={{minHeight: '100%'}}
            >
                <TestsSection></TestsSection>
            </Section>
            <Tabbar>
                <TabbarItem text="Hello">
                    <Icon28Devices />
                </TabbarItem>
                <TabbarItem text="How">
                    <Icon28Stats />
                </TabbarItem>
                <TabbarItem text="Are">
                    <Icon28AddCircle />
                </TabbarItem>
                <TabbarItem text="You">
                    <Icon28Heart />
                </TabbarItem>
            </Tabbar>
        </List>
    )
}