import {AppRoot, Tabbar} from '@telegram-apps/telegram-ui';
import { CellSection } from './components/CellSection';
import {TabbarItem} from "@telegram-apps/telegram-ui/dist/components/Layout/Tabbar/components/TabbarItem/TabbarItem";
import {Icon28Devices} from "@telegram-apps/telegram-ui/dist/icons/28/devices";
import {Icon28Stats} from "@telegram-apps/telegram-ui/dist/icons/28/stats";
import {Icon28AddCircle} from "@telegram-apps/telegram-ui/dist/icons/28/add_circle";
import {Icon28Heart} from "@telegram-apps/telegram-ui/dist/icons/28/heart";

export const App = () => (
  <AppRoot>
    <CellSection />
    <Tabbar>
      <TabbarItem text="PMs & Exs">
        <Icon28Devices />
      </TabbarItem>
      <TabbarItem text="Earn">
        <Icon28AddCircle />
      </TabbarItem>
      <TabbarItem text="Pay">
        <Icon28Heart />
      </TabbarItem>
      <TabbarItem text="Tests" selected={true}>
        <Icon28Stats />
      </TabbarItem>
    </Tabbar>
  </AppRoot>
);
