/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListArgs } from '../models/ListArgs';
import type { TestEx } from '../models/TestEx';
import type { TestExIn } from '../models/TestExIn';
import type { TestExList } from '../models/TestExList';
import type { Tests } from '../models/Tests';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TestExService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Testex Objects List
     * @param requestBody
     * @returns TestExList Successful Response
     * @throws ApiError
     */
    public getTestExList(
        requestBody: ListArgs,
    ): CancelablePromise<TestExList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/TestEx',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Testex Object Create
     * @param requestBody
     * @param itemId
     * @returns TestEx Successful Response
     * @throws ApiError
     */
    public newTestEx(
        requestBody: TestExIn,
        itemId?: (number | null),
    ): CancelablePromise<TestEx> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/TestEx',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Testex Object Get
     * @param itemId
     * @returns TestEx Successful Response
     * @throws ApiError
     */
    public getTestEx(
        itemId: number,
    ): CancelablePromise<TestEx> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/TestEx/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Testex Object Update
     * @param itemId
     * @param requestBody
     * @returns TestEx Successful Response
     * @throws ApiError
     */
    public updTestEx(
        itemId: (number | null),
        requestBody: TestExIn,
    ): CancelablePromise<TestEx> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/TestEx/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Testex Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delTestEx(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/TestEx/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tests
     * @returns Tests Successful Response
     * @throws ApiError
     */
    public getTests(): CancelablePromise<Array<Tests>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Test',
        });
    }
}
