/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AdvStatus {
    defActive = 0,
    active = 1,
    two = 2,
    old = 3,
    four = 4,
    notFound = 9,
}
