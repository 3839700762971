/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListArgs } from '../models/ListArgs';
import type { Pm } from '../models/Pm';
import type { PmIn } from '../models/PmIn';
import type { PmList } from '../models/PmList';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PmService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Pm Objects List
     * @param requestBody
     * @returns PmList Successful Response
     * @throws ApiError
     */
    public getPmList(
        requestBody: ListArgs,
    ): CancelablePromise<PmList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/Pm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pm Object Create
     * @param requestBody
     * @param itemId
     * @returns Pm Successful Response
     * @throws ApiError
     */
    public newPm(
        requestBody: PmIn,
        itemId?: (number | null),
    ): CancelablePromise<Pm> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/Pm',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pm Object Get
     * @param itemId
     * @returns Pm Successful Response
     * @throws ApiError
     */
    public getPm(
        itemId: number,
    ): CancelablePromise<Pm> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/Pm/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pm Object Update
     * @param itemId
     * @param requestBody
     * @returns Pm Successful Response
     * @throws ApiError
     */
    public updPm(
        itemId: (number | null),
        requestBody: PmIn,
    ): CancelablePromise<Pm> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/Pm/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Pm Object Delete
     * @param itemId
     * @returns any Successful Response
     * @throws ApiError
     */
    public delPm(
        itemId: number,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/Pm/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
