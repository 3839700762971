/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AdService } from './services/AdService';
import { AgentService } from './services/AgentService';
import { AssetService } from './services/AssetService';
import { AuthService } from './services/AuthService';
import { CoinService } from './services/CoinService';
import { CountryService } from './services/CountryService';
import { CreditService } from './services/CreditService';
import { CurService } from './services/CurService';
import { DepService } from './services/DepService';
import { DirectionService } from './services/DirectionService';
import { ExService } from './services/ExService';
import { FiatService } from './services/FiatService';
import { InvestmentService } from './services/InvestmentService';
import { InviteService } from './services/InviteService';
import { LimitService } from './services/LimitService';
import { OrderService } from './services/OrderService';
import { PairService } from './services/PairService';
import { PmService } from './services/PmService';
import { PmcurService } from './services/PmcurService';
import { TestExService } from './services/TestExService';
import { UserService } from './services/UserService';
import { VpnService } from './services/VpnService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class Client {
    public readonly ad: AdService;
    public readonly agent: AgentService;
    public readonly asset: AssetService;
    public readonly auth: AuthService;
    public readonly coin: CoinService;
    public readonly country: CountryService;
    public readonly credit: CreditService;
    public readonly cur: CurService;
    public readonly dep: DepService;
    public readonly direction: DirectionService;
    public readonly ex: ExService;
    public readonly fiat: FiatService;
    public readonly investment: InvestmentService;
    public readonly invite: InviteService;
    public readonly limit: LimitService;
    public readonly order: OrderService;
    public readonly pair: PairService;
    public readonly pm: PmService;
    public readonly pmcur: PmcurService;
    public readonly testEx: TestExService;
    public readonly user: UserService;
    public readonly vpn: VpnService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.ad = new AdService(this.request);
        this.agent = new AgentService(this.request);
        this.asset = new AssetService(this.request);
        this.auth = new AuthService(this.request);
        this.coin = new CoinService(this.request);
        this.country = new CountryService(this.request);
        this.credit = new CreditService(this.request);
        this.cur = new CurService(this.request);
        this.dep = new DepService(this.request);
        this.direction = new DirectionService(this.request);
        this.ex = new ExService(this.request);
        this.fiat = new FiatService(this.request);
        this.investment = new InvestmentService(this.request);
        this.invite = new InviteService(this.request);
        this.limit = new LimitService(this.request);
        this.order = new OrderService(this.request);
        this.pair = new PairService(this.request);
        this.pm = new PmService(this.request);
        this.pmcur = new PmcurService(this.request);
        this.testEx = new TestExService(this.request);
        this.user = new UserService(this.request);
        this.vpn = new VpnService(this.request);
    }
}

