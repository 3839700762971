/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_token } from '../models/Body_token';
import type { main__Token } from '../models/main__Token';
import type { TgData } from '../models/TgData';
import type { tortoise_api__oauth__Token } from '../models/tortoise_api__oauth__Token';
import type { UserReg } from '../models/UserReg';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Signup
     * @param requestBody
     * @returns tortoise_api__oauth__Token Successful Response
     * @throws ApiError
     */
    public register(
        requestBody: UserReg,
    ): CancelablePromise<tortoise_api__oauth__Token> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Login For Access Token
     * @param formData
     * @returns tortoise_api__oauth__Token Successful Response
     * @throws ApiError
     */
    public token(
        formData: Body_token,
    ): CancelablePromise<tortoise_api__oauth__Token> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/token',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Token For Tg
     * @param requestBody
     * @returns main__Token Successful Response
     * @throws ApiError
     */
    public getTgToken(
        requestBody: TgData,
    ): CancelablePromise<main__Token> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/tg-token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Token For Twa
     * @returns main__Token Successful Response
     * @throws ApiError
     */
    public getTwaToken(): CancelablePromise<main__Token> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/twa-token',
        });
    }
}
